import { createApp } from "vue";
import { createMetaManager, defaultConfig } from 'vue-meta';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "./assets/scss/app.scss";
import LoadScript from "vue-plugin-load-script";

const app = createApp(App);

app.config.globalProperties.$filters = {
  activePage : 'ourmission',
  goto(elementId) {
    for (const item of document.querySelectorAll(".nav-link.active")) {
      item.classList.remove("active");
    }
    let currentNav = document.getElementById(elementId + "-link");
    currentNav.classList.add("active");
    document.getElementById(elementId).scrollIntoView({
      behavior: "smooth",
    });
  },
};

app.use(store).use(router).use(createMetaManager(false, {
  ...defaultConfig,
  meta: { tag: 'meta', nameless: true },
})).use(LoadScript).mount("#app");
