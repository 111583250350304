<script setup>
import { getBanners } from "./../mixins/fetch_banners.js";
import { Cloudinary } from "@cloudinary/url-gen";
import { Delivery } from "@cloudinary/url-gen/actions";
import { ref, onMounted, onBeforeMount } from "vue";
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

const desktopBanners = ref([]);
const mobileBanners = ref([]);
const isMobile = ref(false);

const cld = new Cloudinary({
  cloud: {
    cloudName: "ilovekickboxing",
  },
});

const sliderOptions = {
  rewind: true,
  type: 'fade',
  perPage: 1,
  autoplay: true,
  pagination: false,
  arrows: false,
};

const getMainBanners = () => {
  getBanners("Franchise", "banner").then((response) => {
    response.forEach((value) => {
      const myImage = cld.image(value.public_id);
      myImage.delivery(Delivery.format("auto")).quality("auto:eco");
      value.imgurl = myImage.toURL();
      if (value.device == "Desktop") {
        // desktopBanners.value.push(value);
        desktopBanners.value.push({
          imgurl: 'https://res.cloudinary.com/ilovekickboxing/image/upload/v1722516154/ILKB_Intermediary_Fran_Dev_Page.01_xjsyoj.jpg',
          device: 'Desktop'
        });
      } else if (value.device == "Mobile") {
        // mobileBanners.value.push(value);
        mobileBanners.value.push({
          imgurl: 'https://res.cloudinary.com/ilovekickboxing/image/upload/v1722516154/ILKB_Intermediary_Fran_Dev_Page.01_xjsyoj.jpg',
          device: 'Mobile'
        });
      }
    });
    //  // Add a specific image URL for the banner
    //  desktopBanners.value.push({
    //   imgurl: 'https://res.cloudinary.com/ilovekickboxing/image/upload/v1722516154/ILKB_Intermediary_Fran_Dev_Page.01_xjsyoj.jpg',
    //   device: 'Desktop'
    // });
  });
};

const checkIsMobile = () => {
  let width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  isMobile.value = width < 640 ? true : false;
};

onBeforeMount(() => {
  window.addEventListener("resize", checkIsMobile);
});

onMounted(() => {
  checkIsMobile();
  getMainBanners();
});
</script>
<template>
  <section class="home-banner-wrapper position-relative" id="herobanner">
    <template v-if="isMobile">
      <div class="home_banner_image">
        <div class="homebanner_slider">
          <div class="homebanner_slider_main">
            <template v-if="mobileBanners && mobileBanners.length > 0">
              <Splide :options="sliderOptions">
                <SplideSlide v-for="(banner, index) in mobileBanners" :key="index">
                  <div class="img-wrap justify-content-center">
                    <img class="img-fluid" :src="banner.imgurl" />
                  </div>
                </SplideSlide>
              </Splide>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="homebanner_slider">
        <div class="homebanner_slider_main">
          <template v-if="desktopBanners && desktopBanners.length > 0">
            <Splide :options="sliderOptions">
              <SplideSlide v-for="(banner, index) in desktopBanners" :key="index">
                <div class="img-wrap justify-content-center">
                  <img class="img-fluid" :src="banner.imgurl" />
                </div>
              </SplideSlide>
            </Splide>
          </template>
        </div>
      </div>
    </template>
    <div class="home_banner_content">
      <div class="container-fluid">
        <div class="row align-items-md-center">
          <div class="col-12 col-md-7 col-lg-7 col-xl-6 position-static">
            <div class="content-wrap">
              <div>
                <h1 class="banner_title">Discover The I Love Kickboxing</h1>
                <h2 class="banner_sub_title">
                 <strong>Franchise Opportunity</strong>
                  <!-- <span class="font-PPFormulaRegullar">Franchise Opportunity</span> -->
                </h2>
                <div class="d-flex align-items-center">
                  <p class="banner_desc">We’re excited about your interest in joining our ILKB
                    franchise system! If you’re passionate about kickboxing
                    fitness and helping others, this may be the perfect
                    opportunity for your future business. Explore the
                    following links for more information and fill out the form
                    below to get in touch with our team.</p>
                  <button type="button" class="btn btn-circle" @click="$filters.goto('getstarted')">
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
