import { post } from "./http_handler"

export const getBanners = (placement, image_location) => {
    return post('/', {
        query: `query {
          banners(
            test_image: "active"
            placement: "` + placement + `"
            image_location: "` + image_location + `"
          ) {
            banner_id
            studio_id
            placement
            device
            image_location
            text
            public_id
            start_date
            end_date
            status
            country_id
          }
        }`
    }).then(response => response?.data?.data?.banners)
        .catch(err => err);
}