<template>
  <header class="header_wrp">
    <div class="container-fluid">
      <div class="header_main_wrp">
        <div class="row align-items-center">
          <div class="col-8">
            <a class="navbar-brand col p-0 m-0" href="https://www.ilovekickboxing.com/">
              <img
                class="img-fluid"
                src="https://res.cloudinary.com/ilovekickboxing/image/upload/f_auto,fl_lossy,q_auto:eco/v1/Brand/logo-main-white-1.png"
                style="max-height: 75px; max-width: 250px;"
                alt="iLoveKickboxing Logo"
              />
            </a>
          </div>
          <div class="col-4 d-flex justify-content-end">
            <span class="btn btn-primary" @click="$filters.goto('getstarted')" id="getstarted-link">LEARN MORE</span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
