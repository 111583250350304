<script setup>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Banner from "@/components/Banner.vue";
import Navbar from "@/components/Navbar.vue";
import WhatYouGet from "@/components/WhatYouGet.vue";
import BusinessModel from "@/components/BusinessModel.vue";
import GetStarted from "@/components/GetStarted.vue";
import Footer from "@/components/Footer.vue";
// import { useRoute } from 'vue-router'
import { post } from "./../mixins/http_handler";
import { ref, onMounted, onBeforeMount } from "vue";
import WhyUs from "../components/WhyUs.vue";
import OurSuccessStories from "../components/OurSuccessStories.vue";
import InvestmentRequirements from "../components/InvestmentRequirements.vue";
import Press from "../components/Press.vue";
import Process from "../components/Process.vue";

const metaTags = ref([]);
const metaScripts = ref([]);
const metaNoScripts = ref([]);
const metaLinks = ref([]);
// const route = useRoute()
const isMobile = ref(false);

import { useMeta } from "vue-meta";

const getMetaInfo = () => {
  let href = process.env.VUE_APP_META_TAG_URL;
  post("/", {
    query:
      `{metaTags(url:"` +
      href +
      `")
        {website_seo_tracking_tags_id
            page_name
            page_section
            tracking_type
            tracking_comment
            tracking_sequence
            tracking_value
          }}`
  })
    .then((response) => {
      console.log(response.data.data.metaTags, "MetaTags");
      const metaTagsApi = response.data.data.metaTags;
      /* Metas */
      metaTagsApi
        .filter(function (metaTag) {
          return (
            metaTag.tracking_type == "meta" &&
            (metaTag.page_name == "all" || metaTag.page_name == "main")
          );
        })
        .forEach(metaTag => {
          metaTags.value.push(...JSON.parse(metaTag.tracking_value));
        });
      /* Scripts */
      metaTagsApi
        .filter(function (metaTag) {
          return (
            metaTag.tracking_type == "script" &&
            (metaTag.page_name == "all" || metaTag.page_name == "main")
          );
        })
        .forEach(metaTag => {
          metaScripts.value.push(...JSON.parse(metaTag.tracking_value));
        });
      /* No Scripts */
      metaTagsApi
        .filter(function (metaTag) {
          return (
            metaTag.tracking_type == "noscript" &&
            (metaTag.page_name == "all" || metaTag.page_name == "main")
          );
        })
        .forEach(metaTag => {
          metaNoScripts.value.push(...JSON.parse(metaTag.tracking_value));
        });
      /* Links */
      metaTagsApi
        .filter(function (metaTag) {
          return (
            metaTag.tracking_type == "link" &&
            (metaTag.page_name == "all" || metaTag.page_name == "main")
          );
        })
        .forEach(metaTag => {
          metaLinks.value.push(...JSON.parse(metaTag.tracking_value));
        });
    })
    .catch((err) => {
      console.log(err);
    });
};
const checkIsMobile = () => {
  let width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  isMobile.value = width < 640 ? true : false;
};
onBeforeMount(() => {
  window.addEventListener("resize", checkIsMobile);
});
onMounted(() => {
  checkIsMobile();
  getMetaInfo();
});

const scrollToView = (id) => {
  console.log(id)
  let element = document.getElementById(id);
  let headerOffset = 80;
  let elementPosition = element.getBoundingClientRect().top;
  let offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth"
  });


  // element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
}

useMeta({
  title: "Franchise - iLoveKickboxing",
  meta: metaTags.value,
  script: metaScripts.value,
  noscript: metaNoScripts.value,
  link: metaLinks.value
});

</script>

<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <div class="home-wrapper">
    <Header />
    <Banner />
    <nav class="navbar navbar-light sticky-top d-none d-md-block" id="navbarmenu"
      v-bind:class="[isSticky ? stickyClass : '']">
    </nav>
    <template v-if="isMobile">
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
              aria-expanded="true" aria-controls="collapseOne" @click="scrollToView('headingOne')">
              <span class="nav_count_nos">01</span> Our Mission
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <WhatYouGet />
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"
              @click="scrollToView('headingTwo')">
              <span class="nav_count_nos">02</span> Business Model
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <BusinessModel />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <WhatYouGet />
      <BusinessModel />
      <!-- <WhyUs/>
      <OurSuccessStories/>
      <InvestmentRequirements/>
      <Process/> -->
      <!-- <Press/> -->
    </template>

    <GetStarted />
    <Footer />
  </div>
</template>
