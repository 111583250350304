<script setup>
  import { ref, onMounted } from "vue";
  import { getBanners } from "../mixins/fetch_banners.js";
  import {Cloudinary} from "@cloudinary/url-gen";
  import {Delivery} from "@cloudinary/url-gen/actions";

  const banners = ref([]);
  const banners2 = ref([]);
  const cld = new Cloudinary({
    cloud: {
      cloudName: "ilovekickboxing",
    },
  });

  const getMissionBanners = () => {
    getBanners("Franchise", "mission-1").then((response) => {
        banners.value.push({
          imgurl: 'https://res.cloudinary.com/ilovekickboxing/image/upload/v1722516161/ILKB_Intermediary_Fran_Dev_Page.02_flnwry.jpg',
          device: 'Desktop'
        });
        banners.value.push({
          imgurl: 'https://res.cloudinary.com/ilovekickboxing/image/upload/v1722516161/ILKB_Intermediary_Fran_Dev_Page.02_flnwry.jpg',
          device: 'Mobile'
        });
      // });
    });
  };
  onMounted(() => {
    getMissionBanners();
    console.log(banners.value,"ban");

  });
</script>
<template>
  <section class="section-wrapper what-you-get-wrapper" id="ourmission">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-9 col-xl-8 mx-auto text-center">
            <span class="section_title">Our mission</span>
            <h2 class="section_subtitle"><span class="font-PPFormulaRegullar">Building Community Through Kickboxing</span></h2>
        </div>
      </div>
      <div class="row align-items-center justify-content-between">
        <div class="col-12 col-md-6 mb-3 mb-md-0">
          <div class="mission_imgbox">
            <div v-for="(banner, index) in banners" :key="index">
              <div
                  v-if="banner.device == 'Desktop'"
                  class="d-none d-md-block img-wrap mission_imgone"
              >
                <img :src="banner.imgurl"/>
              </div>
              <div 
                v-if="banner.device == 'Mobile'" 
                class="d-block d-md-none img-wrap mission_imgone"
              >
                <img :src="banner.imgurl"/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-5">
          <div class="d-flex flex-column">
            <p>We cultivate inclusive communities that foster confidence, celebrate the journey of growth, and empower people to connect with their inner strength through our kickboxing classes.<br> <br> We are committed to providing a supportive and motivational environment where every member can thrive. Join us in our mission to uplift and inspire, as we work together to achieve personal goals and create lasting positive change.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="lovethefight_wrp">
      <div class="lovefirstrow">
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
      </div>
      <div class="lovefirstrow lovesecondrow">
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
        <div class="lovetext"><span>Love</span>TheFight</div>
      </div>
    </div>
  </section>
  
</template>
