<script setup>
import { onBeforeMount, onUnmounted, ref } from "vue";

const isSticky = ref(false);
const stickyClass = ref("is_sticky");
const scrollPosition = ref(0);

const handleScroll = () => {
  scrollPosition.value = window.scrollY;
  if (scrollPosition.value >= 600) {
    isSticky.value = true;
  } else {
    isSticky.value = false;
  }
};

onBeforeMount(() => {
  window.addEventListener("scroll", handleScroll);
});
onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>
<template>
  <nav
    class="navbar navbar-light sticky-top d-none d-md-block"
    id="navbarmenu"
    v-bind:class="[isSticky ? stickyClass : '']"
  >
    <div class="container-fluid">
      <div class="collapse navbar-collapse show" id="navbarSupportedContent">
        <ul class="navbar-nav navbar-center mx-auto">
          <li class="logo-sticky" key="headernavup" v-if="isSticky">
            <a
                @click="$filters.goto('herobanner')">
              <svg
                width="138"
                height="36"
                viewBox="0 0 138 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_803_1601)">
                  <path d="M0 16.401V0H3.95238V16.401H0Z" fill="white" />
                  <path
                    d="M10.8475 16.401V0H14.7999V12.9436H21.1151V16.401H10.8475Z"
                    fill="white"
                  />
                  <path
                    d="M46.4834 16.401H42.5525L36.3662 0H40.7912L44.5717 11.6255H44.6576L48.4167 0H52.7772L46.4834 16.401Z"
                    fill="white"
                  />
                  <path
                    d="M53.916 16.401V0H64.871V3.34934H57.718V6.43938H64.4843V9.61585H57.718V13.03H65.3006V16.401H53.916Z"
                    fill="white"
                  />
                  <path
                    d="M9.96687 35.5463L3.90942 28.0049H3.86646V35.5463H0V19.167H3.86646V25.8441H3.9309L9.75206 19.167H14.6711L7.64699 26.7084L15.0792 35.5463H9.96687Z"
                    fill="white"
                  />
                  <path
                    d="M16.0029 35.5463V19.167H19.9553V35.568H16.0029V35.5463Z"
                    fill="white"
                  />
                  <path
                    d="M30.1155 35.9998C25.0891 35.9998 21.3516 32.4776 21.3516 27.3347C21.3516 22.0838 25.218 18.7129 30.2014 18.7129C32.4784 18.7129 34.9486 19.5556 36.3018 21.1114L33.6383 23.7909C32.908 22.7753 31.5977 22.2783 30.3518 22.2783C27.6023 22.2783 25.6476 24.4176 25.6476 27.3131C25.6476 30.2951 27.5594 32.3479 30.2659 32.3479C31.8125 32.3479 33.0368 31.6565 33.7242 30.6841L36.4737 33.2771C35.013 34.941 32.865 35.9998 30.1155 35.9998Z"
                    fill="white"
                  />
                  <path
                    d="M47.6436 35.5463L41.5862 28.0049H41.5432V35.5463H37.6768V19.167H41.5432V25.8441H41.6077L47.4288 19.167H52.3478L45.3237 26.7084L52.7559 35.5463H47.6436Z"
                    fill="white"
                  />
                  <path
                    d="M60.2527 35.5463H53.7656V19.167H60.2527C62.7874 19.167 65.7946 20.0746 65.7946 23.3807C65.7946 25.1958 64.7206 26.3843 63.131 26.9245V26.9677C64.9998 27.2918 66.4605 28.6316 66.4605 30.7924C66.4605 34.3579 63.3458 35.5463 60.2527 35.5463ZM59.4794 22.3002H57.5462V25.6712H59.6727C61.1549 25.6712 61.9926 25.0445 61.9926 23.8777C61.9926 22.7972 61.1334 22.3002 59.4794 22.3002ZM59.7372 28.61H57.5462V32.3699H59.7586C61.0045 32.3699 62.5296 32.0241 62.5296 30.4251C62.5296 29.0421 61.4126 28.61 59.7372 28.61Z"
                    fill="white"
                  />
                  <path
                    d="M76.4485 35.9998C71.3576 35.9998 67.5771 32.4776 67.5771 27.2915C67.5771 22.0406 71.3576 18.7129 76.4485 18.7129C81.5608 18.7129 85.3413 22.019 85.3413 27.2915C85.3413 32.4776 81.5608 35.9998 76.4485 35.9998ZM76.4485 22.2783C73.6775 22.2783 71.8087 24.4176 71.8087 27.2699C71.8087 30.2303 73.7205 32.3696 76.4485 32.3696C79.1979 32.3696 81.1312 30.2303 81.1312 27.2699C81.1312 24.4176 79.2194 22.2783 76.4485 22.2783Z"
                    fill="white"
                  />
                  <path
                    d="M95.2224 35.5463L91.6567 29.7552L88.2198 35.5463H83.5156L89.2294 26.8813L83.9882 19.167H88.7353L91.7641 24.2666L94.7498 19.167H99.3251L94.1699 26.9029L100.055 35.568H95.2224V35.5463Z"
                    fill="white"
                  />
                  <path
                    d="M100.936 35.5463V19.167H104.888V35.568H100.936V35.5463Z"
                    fill="white"
                  />
                  <path
                    d="M116.617 35.5463L110.066 24.8285H109.98L110.066 35.5463H106.242V19.167H110.732L117.262 29.8633H117.326L117.24 19.167H121.064V35.568H116.617V35.5463Z"
                    fill="white"
                  />
                  <path
                    d="M131.266 35.9782C126.068 35.9782 122.309 32.4992 122.309 27.3347C122.309 22.0838 126.175 18.7129 131.158 18.7129C133.715 18.7129 136.077 19.5772 137.452 20.8954L134.96 23.7477C134.209 22.8618 132.898 22.2135 131.309 22.2135C128.538 22.2135 126.519 24.396 126.519 27.3347C126.519 30.3383 128.302 32.4992 131.481 32.4992C132.404 32.4992 133.264 32.3696 133.951 32.067V29.085H130.815V25.887H137.538V34.5736C135.949 35.3948 133.779 35.9782 131.266 35.9782Z"
                    fill="white"
                  />
                  <path
                    d="M27.3448 15.7958L34.734 8.36242C35.6577 7.43325 36.1517 6.20156 36.1517 4.90504C36.1517 3.60852 35.6362 2.37683 34.734 1.44766C33.8104 0.518483 32.586 0.0214844 31.2972 0.0214844C29.9869 0.0214844 28.784 0.540092 27.8603 1.44766L26.7433 2.5713L25.6264 1.44766C23.7361 -0.453906 20.6429 -0.453906 18.7312 1.44766C16.8409 3.34922 16.8409 6.46086 18.7312 8.38403L26.7433 16.4441L27.3448 15.7958Z"
                    fill="#BE1F24"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_803_1601">
                    <rect width="137.538" height="36" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              @click="$filters.goto('ourmission')"
              id="ourmission-link"
            >
              <span class="nav_count_nos">01</span> Our Mission
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              @click="$filters.goto('whyus')"
              id="whyus-link"
            >
              <span class="nav_count_nos">02</span> Why us
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              @click="$filters.goto('successstories')"
              id="successstories-link"
            >
              <span class="nav_count_nos">03</span> Success Stories
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              @click="$filters.goto('requirements')"
              id="requirements-link"
            >
              <span class="nav_count_nos">04</span> Requirements
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              @click="$filters.goto('process')"
              id="process-link"
            >
              <span class="nav_count_nos">05</span> Process
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              @click="$filters.goto('press')"
              id="press-link"
            >
              <span class="nav_count_nos">06</span> Press
            </a>
          </li>
          <li key="headernavup" v-if="isSticky">
            <a 
              class="requestinfo-hdbtn btn btn-primary"
              @click="$filters.goto('getstarted')"
              id="getstarted-link"
            >
              LEARN MORE
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
