<script setup>
import { ref, onMounted } from "vue";
import { getBanners } from "../mixins/fetch_banners.js";
import { Cloudinary } from "@cloudinary/url-gen";
import { Delivery } from "@cloudinary/url-gen/actions";

const banners = ref([]);
const banners2 = ref([]);
const cld = new Cloudinary({
  cloud: {
    cloudName: "ilovekickboxing",
  },
});

const franchiseUrl = process.env.VUE_APP_FRANCHISE_URL;

const getMissionBanners = () => {
  getBanners("Franchise", "mission-1").then((response) => {
    banners.value.push({
      imgurl: 'https://res.cloudinary.com/ilovekickboxing/image/upload/v1722516161/ILKB_Intermediary_Fran_Dev_Page.02_flnwry.jpg',
      device: 'Desktop'
    });
  });
};
onMounted(() => {

});
</script>
<template>
  <section class="section-wrapper what-you-get-wrapper" id="businessmodel">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-between">
        <div class="col-12 col-md-7">
          <h2 class="business_sub_title">
            <strong>CHOOSE YOUR BUSINESS MODEL</strong>
          </h2>
          <div class="d-flex align-items-center">
            <p class="banner_desc">We are excited to provide two distinct franchise models for I Love Kickboxing to
              suit your needs as a business owner. Whether you want to dive fully into the brand with your own
              brick-and-mortar location or you have space within an existing business that could benefit from I Love
              Kickboxing’s signature classes, we’ve got the right solution for you! Explore your options below now and
              discover how you can join our passionate community and empower others through ILKB!</p>
          </div>
        </div>
        <div class="col-12 col-md-5">
          <div class="d-flex flex-column">
            <img
              src="https://res.cloudinary.com/ilovekickboxing/image/upload/f_auto,fl_lossy,q_auto:eco/Brand/glove-bump-1.png"
              alt="ILKB Gloves" class="img-fluid" />
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-between">
        <div class="col-12 col-md-6 left-div">
          <div>
            <h2 class="business_sub_title">
              <strong>Option 1: Standard</strong>
            </h2>
            <h3 class="business_sub_title_text">
              Want to open a new brick-and-mortar location?
            </h3>
            <br>
            <p>Bring the proven I Love Kickboxing program to your local community by building and opening a brand-new,
              state-of-the-art kickboxing studio.</p>
            <span class="section_title">Key Benefits</span>
            <ul>
              <li>Long-term franchise partnership</li>
              <li>Your own fitness studio</li>
              <li>Streamlined equipment and technology</li>
              <li>Dedicated support from Home Office</li>
              <li>Plug-and-play systems for marketing, operations, and sales</li>
            </ul>
            <a :href="`${franchiseUrl}standard`" class="btn btn-circle" target="_blank">
              Learn More
            </a>

            <!-- <button type="button" class="btn btn-circle" @click="$filters.goto('getstarted')">
              Learn More
            </button> -->
          </div>
        </div>

        <div class="col-12 col-md-6 right-div">
          <div class="business-model-option-margin-left">
            <h2 class="business_sub_title">
              <strong>Option 2: Lightweight</strong>
            </h2>
            <h3 class="business_sub_title_text">
              Own an existing studio space or martial arts business?
            </h3>
            <br>
            <p>Seamlessly integrate our I Love Kickboxing classes into your existing services with our Lightweight
              program.</p>
            <span class="section_title">Key Benefits</span>
            <ul>
              <li>Short-term franchise partnership</li>
              <li>No studio buildout required; use your existing location</li>
              <li>Streamlined equipment and technology</li>
              <li>Dedicated support from Home Office</li>
              <li>Plug-and-play systems for marketing, operations, and sales</li>
            </ul>
            <a :href="`${franchiseUrl}lightweight`" class="btn btn-circle" target="_blank">
              Learn More
            </a>

            <!-- <button type="button" class="btn btn-circle" @click="$filters.goto('getstarted')">
              Learn More
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </section>

</template>
