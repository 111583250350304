<script setup>
import { ref, onMounted } from "vue";
import { post } from "./../mixins/http_handler";
// import { defineProps } from "vue";

const HQStudio = ref([]);
const currentYear = ref(new Date().getFullYear()); // Get the current year

const getHQStudio = () => {
   post('/', {
      query: `query {
         location(id: 0) {
            Name
            facebook_link
            instagram_link
            twitter_link
            youtube_link
            tiktok_link
         }
      }`
})
    .then((response) => {
      HQStudio.value = response?.data?.data?.location;
    })
    .catch((err) => {
      console.log(err);
    });
};

onMounted(() => {
  getHQStudio();
});
</script>
<template>
  <footer class="footer-wrapper" id="footer">
    <div class="container-fluid">
      <div
        class="row ft-log-wrap"
      >
        <div class="col-12 col-md-8 d-flex align-items-start align-items-md-center flex-column flex-md-row">
           <img
            class="img-fluid"
            src="https://res.cloudinary.com/ilovekickboxing/image/upload/f_auto,fl_lossy,q_auto:eco/Brand/logo-main-1.png"
            style="max-height: 16px"
            alt="iLoveKickboxing Logo"
          />
          <span class="copyright_text">© 2009-{{ currentYear }}  all rights reserved.</span>
        </div>
        <div class="col-12 col-md-4">
            <a href="//www.facebook.com/<?= {{ HQStudio.facebook_link }} ?>"
               class="social-icon" target="_blank">
                <i class="fab fa-facebook-f fa-lg"></i>
            </a>
            <a href="//www.instagram.com/<?= {{ HQStudio.instagram_link }}  ?>"
               class="social-icon" target="_blank">
                <i class="fab fa-instagram fa-lg"></i>
            </a>
            <a href="//www.twitter.com/<?= {{ HQStudio.twitter_link }}  ?>"
               class="social-icon" target="_blank">
                <i class="fab fa-twitter fa-lg"></i>
            </a>
            <a href="//www.youtube.com/<?= {{ HQStudio.youtube_link }}  ?>"
               class="social-icon" target="_blank">
                <i class="fab fa-youtube fa-lg"></i>
            </a>
            <a href="//www.tiktok.com/<?= {{ HQStudio.tiktok_link }}  ?>"
               class="social-icon" target="_blank">
                <i class="fab fa-tiktok fa-lg"></i>
            </a>
        </div>
      </div>
    </div>
  </footer>
</template>
