<template>
  <div class="thank-you-page">
    <div class="thank-you-container">
      <h2>THANK YOU! <br />WE’RE EXCITED TO CONNECT.</h2>
      <p>
        Thank you for your interest in an I Love Kickboxing franchise! Although
        we are currently not awarding new licenses at this time, we have added
        you to our waitlist and will notify you as soon as new opportunities
        become available.
      </p>
      <p>
        We are thrilled to announce that ILKB is now under the new ownership of
        Striking Brands, which also includes 9Round Kickboxing Fitness. Our team
        is dedicated to providing exceptional support for both current and
        future franchise partners.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThankYouPage",
};
</script>

<style scoped>
.thank-you-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  /* background: linear-gradient(135deg, #f06, #ff9e80);*/
  color: #fff;
  font-family: "Arial", sans-serif;
  padding: 20px;
  box-sizing: border-box;
}

.thank-you-container {
  max-width: 100%;
  width: 700px;
  text-align: center;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

h1 {
  font-size: 2.2em;
  margin-bottom: 15px;
  font-weight: bold;
}

p {
  font-size: 1.1em;
  line-height: 1.4;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2em;
  }

  p {
    font-size: 1em;
  }
}
</style>
